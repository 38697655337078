import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------

const roles = ['Full Stack Developer', 'Intern', 'Intern', 'Intern'];
const qualifications = ['Just Skills', 'CS or IT student', 'CS or IT student', 'CS or IT student'];
const skills = [
  'Flutter, ReactJS, TailwindCSS, GraphQL, NodeJS, MongoDB',
  'Flutter, GraphQL',
  'ReactJS or NextJS, GraphQL',
  'Backend - NodeJS, MongoDB and GraphQL',
];
const users = [...Array(4)].map((_, index) => ({
  id: index + 1,
  avatarUrl: `/assets/images/avatars/avatar_${index + 1}.jpg`,
  name: roles[index],
  company: skills[index],
  isVerified: faker.datatype.boolean(),
  status: 'Hiring',
  role: qualifications[index],
}));

export default users;
