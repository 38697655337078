/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
// Material Kit 2 React components

// Material Kit 2 React examples
import HorizontalTeamCard from '../../components/Cards/TeamCards/HorizontalTeamCard';

// Images
import team1 from '../../components/my-imgs/selvaviswanath.jpg';
import team2 from '../../components/my-imgs/syed-imran.jpg';
import team3 from '../../components/my-imgs/pandi.jpg';
import team4 from '../../components/my-imgs/dilli.jpg';
function Team() {
  return (
    <Box component="section" variant="gradient"  position="relative" py={6} px={{ xs: 2, lg: 0 }} mx={-2}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <Typography variant="h3" color="white">
              Our Team
            </Typography>
            <Typography variant="body2" color="white" opacity={0.8}>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Vishwanath Selva"
                position={{ color: 'info', label: 'Co-CEO, Founder, Lead Developer' }}
                description=""
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Syed imran"
                position={{ color: 'info', label: 'Co-CEO, Founder, HR' }}
                description=""
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team3}
                name="Pandian Sambath"
                position={{ color: 'info', label: 'Digital Marketing head' }}
                description=""
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Box mb={1}>
              <HorizontalTeamCard
                image={team4}
                name="Dilli babu"
                position={{ color: 'info', label: 'Robotics & IOT engineer' }}
                description=""
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Team;
