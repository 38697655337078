import React from 'react';
import { AppBar, Container, Toolbar, Typography, Link } from '@mui/material';
// import Iconify from '../iconify/Iconify';
// import { alpha } from '@mui/material/styles';
import {  Avatar, IconButton} from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
// mocks_
import logo from '../../_mock/logo';
import  TwitterIcon  from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
// import Button from 'src/theme/overrides/Button';

const CenteredFooter = () => {
  return (
    <AppBar position="static" sx={{backgroundColor:"#22334d", m:0, mt:8}} >
      <Container maxWidth="lg">
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Link href="https://zutok-india.web.app/jobs" color="#f1f1f1" sx={{ marginRight: 2}} style={{fontFamily:"sans-serif"}} >
              Careers
            </Link>
            <Link href="https://zutok-india.web.app/blog" color="#f1f1f1" sx={{ marginRight: 2 }} style={{fontFamily:"sans-serif"}}>
              Blog
            </Link>
            <Link href="https://zutok-india.web.app/products" color="#f1f1f1" sx={{ marginRight: 2 }} style={{fontFamily:"sans-serif"}}>
              Services
            </Link>
            <Link href="mailto:vishwanath@zutok.in" color="#f1f1f1" sx={{ marginRight: 2 }} style={{fontFamily:"sans-serif"}}>
              Contact
            </Link>
          </div>
          
          <Typography variant="h3" color="#000000" style={{fontFamily:"sans-serif"}}>
          <IconButton>
            <Avatar src={logo.photoURL} alt="photoURL" />
          </IconButton>
            
          </Typography>
        </Toolbar>
      </Container>
      
      <Container maxWidth="lg" sx={{ display:'flex',  borderTop: '1px solid rgba(255, 255, 255, 0.12)', paddingTop: 1,  justifyContent:'center'}}>
      
      <Link href="https://instagram.com/zutok.tech" sx={{ color:'#ff9f1c',marginRight: 2, placeItems:'center'}} style={{fontFamily:"sans-serif"}} >
        
              <InstagramIcon/>
            </Link>
            <Link href="https://www.linkedin.com/company/zutok-softwares/" sx={{ color:'#ff9f1c', marginRight: 2, placeItems:'center'}} style={{fontFamily:"sans-serif"}} >
            
              <LinkedInIcon/>
            </Link>
            <Link href="https://twitter.com/Zutok_Tech" sx={{ color:'#ff9f1c', marginRight: 2, placeItems:'center'}} style={{fontFamily:"sans-serif"}} >
              <TwitterIcon/>
            </Link>
            <Link href="mailto:vishwanath@zutok.in" sx={{ color:'#ff9f1c', marginRight: 2, placeItems:'center'}} style={{fontFamily:"sans-serif"}} >
              <EmailIcon/>
            </Link>
      </Container>
      <Container maxWidth="lg" sx={{ borderTop: '1px solid rgba(255, 255, 255, 0.12)', paddingTop: 1,  }}>
        <Typography variant="body2" color="inherit" align="center">
          Zutok Softwares India
          &copy; {new Date().getFullYear()} All rights reserved.
        </Typography>
      </Container>
      
    </AppBar>
  );
};

export default CenteredFooter;
