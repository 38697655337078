import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const POST_TITLES = [
  // 'Syed Imran & Pandian: 12 Aug 2023Our Experience at the Tamilnadu Startup Thiruvizha 2023',
  // 'Selva viswanath:      12 Aug 2023Launching Innovation: Our Tech Startup Takes Flight!',
  'Dilli Babu:           28 Aug 2023A New Robotics engineer joins zutok: M.Tech Robotics from Karunya university',
];


const posts = [...Array(1)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: `/assets/my-imgs/cover_${index + 1}.jpg`,
  title: POST_TITLES[index].substring(33),
  // createdAt: faker.date.past(),
  createdAt: new Date(POST_TITLES[index].substring(22,33)),
  view: faker.datatype.number(),
  comment: faker.datatype.number(),
  share: faker.datatype.number(),
  favorite: faker.datatype.number(),
  author: {
    name: POST_TITLES[index].substring(0, 22),
    avatarUrl: `/assets/ZutokLogo.png`,
  },
}));

export default posts;
