import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
// // components
// import { ProductList } from '../sections/@dashboard/products';
// // mock
// import PRODUCTS from '../_mock/products';
import CenteredFooter from '../components/CenteredFooter';
import Pricing from './Pricing';

// ----------------------------------------------------------------------

export default function ProductsPage() {


  return (
    <>
      <Helmet>
        <title> Zutok | Services</title>
        <meta name="description" content="Zutok Softwares: Igniting Innovation through Youthful Brilliance. We are a pioneering tech startup, exclusively led by new grads and students. Specializing in custom software, mobile and web app development, UI/UX design, networking, and operating systems, we reimagine possibilities and craft digital dreams into reality. Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="keywords" content="Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="author" content="Zutok Softwares" />
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Our Services and Products
        </Typography>

        {/* <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack> */}

        <Pricing />
        {/* <ProductCartWidget /> */}
      </Container>
      <CenteredFooter/>
    </>
  );
}
