import { Helmet } from 'react-helmet-async';
import Card from '@mui/material/Card';
// import { styled } from '@mui/material/styles';
// import { AppBar, Toolbar,IconButton} from '@mui/material';
// // utils
// import { styled } from '@mui/material/styles';

// import { bgBlur } from '../utils/cssStyles';
// components
// import PRODUCTS from '../_mock/products';
// import { ProductList } from '../sections/@dashboard/products';
// import Iconify from '../components/iconify';
// import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import { BlogPostCard} from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
// import Iconify from '../components/iconify';
// sections
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';
// import Team from '../sections/team/Team';
import CenteredFooter from '../components/CenteredFooter';
import Pricing from './Pricing';
// import MuiFooter from '../components/mui-footer';

// ----------------------------------------------------------------------

// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'popular', label: 'Popular' },
//   { value: 'oldest', label: 'Oldest' },
// ];

// const NAV_WIDTH = 280;

// const HEADER_MOBILE = 64;

// const HEADER_DESKTOP = 92;

// const StyledRoot = styled(AppBar)(({ theme }) => ({
//   ...bgBlur({ color: theme.palette.background.default }),
//   boxShadow: 'none',
//   [theme.breakpoints.up('lg')]: {
//     width: `calc(100% - ${NAV_WIDTH + 1}px)`,
//   },
// }));

// const StyledToolbar = styled(Toolbar)(({ theme }) => ({
//   minHeight: HEADER_MOBILE,
//   [theme.breakpoints.up('lg')]: {
//     minHeight: HEADER_DESKTOP,
//     padding: theme.spacing(0, 5),
//   },
// }));

export default function DashboardAppPage() {
  // const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Zutok Softwares | Home </title>
        <meta name="description" content="Zutok Softwares: Igniting Innovation through Youthful Brilliance. We are a pioneering tech startup, exclusively led by new grads and students. Specializing in custom software, mobile and web app development, UI/UX design, networking, and operating systems, we reimagine possibilities and craft digital dreams into reality. Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="keywords" content="Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="author" content="Zutok Softwares" />
      </Helmet>

      <Container maxWidth="xl" sx={{alignItems:'center'}} >
        <Grid container spacing={3} >
          <Card
            sx={{
              marginLeft: 3,
              color: '#ffffff',
              backgroundColor: '#03071e',
              
            }} 
          >
            <Box
              sx={{
                textAlign: { xs: 'center' },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ mb: 3 }} p={{ sm:3, md:2, xs:3, lg:3 , }} 
              >
                <Typography
                  component="h2"
                  sx={{
                    position: 'relative',
                    fontSize: { xs: 30, md: 65 },
                    letterSpacing: 1.5,
                    paddingTop: 3,
                    fontWeight: 'bold',
                    lineHeight: 1.3,
                  }}
                >
                  Crafting Your Tech Fantasies,{' '}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: 'inherit',
                      fontWeight: 'inherit',
                      position: 'relative',
                      '& svg': {
                        position: 'absolute',
                        top: -16,
                        right: -21,
                        width: { xs: 22, md: 30 },
                        height: 'auto',
                      },
                    }}
                  >
                    {' '}
                  </Typography>
                  <br />
                  Where Innovation and Ideas Abode.
                </Typography>
              </Box>

              <Typography sx={{ color: '#fca311', lineHeight: 1.6 }} marginBottom={3}>
                {`
                  Whispering Bytes, a Digital Symphony Unveils,
                  Where Zutok's Young Minds Spin Techno Spells.`}
              </Typography>
            </Box>
          </Card>
          
          {/* <Card 
            sx={{
              marginTop: 5,
              marginLeft: 3,
              padding: 3,
              color: '#ffffff',
              backgroundColor: '#000000',
            }}>
            <ProductList products={PRODUCTS}/>
          </Card> */}
          {/* <Card 
          sx={{
              marginTop: 5,
              marginLeft: 3,
              padding: 3,
              color: '#ffffff',
              backgroundColor: '#000000',
            }}> */}
            <Pricing/>
          {/* </Card> */}
          {/* <Card
            sx={{
              marginTop: 5,
              marginLeft: 3,
              padding: 3,
              backgroundColor: '#fca311',
            }}
          >
            <Team />
          </Card> */}
            {/* <Typography variant='h2' sx={{color:'#f5f5f5', width:'100%', textAlign:'center', flexFlow:'row'}}> <br/>our Blog <br/></Typography>
          
            {POSTS.slice(0, 6).map((post, index) => (
              <BlogPostCard key={post.id} post={post} index={index} />
            ))} */}
          

          
        </Grid>
      </Container>
      <CenteredFooter/>
      
    </>
  );
}
