import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Stack, Typography } from '@mui/material';
// components
// import Iconify from '../components/iconify';
import { BlogPostCard,BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import CenteredFooter from '../components/CenteredFooter';

// ----------------------------------------------------------------------

// const SORT_OPTIONS = [
//   { value: 'latest', label: 'Latest' },
//   { value: 'popular', label: 'Popular' },
//   { value: 'oldest', label: 'Oldest' },
// ];

// ----------------------------------------------------------------------

export default function BlogPage() {
  return (
    <>
      <Helmet>
        <title> Zutok | Blog</title>
        <meta name="description" content="Zutok Softwares: Igniting Innovation through Youthful Brilliance. We are a pioneering tech startup, exclusively led by new grads and students. Specializing in custom software, mobile and web app development, UI/UX design, networking, and operating systems, we reimagine possibilities and craft digital dreams into reality. Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="keywords" content="Software development app web mobile android ios custom modern top nodejs node react reactjs flutter backend fullstack Chennai india tamil nadu " />
  <meta name="author" content="Zutok Softwares" />
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button> */}
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          {/* <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>

        <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
      <CenteredFooter/>
    </>
  );
}
