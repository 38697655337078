import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
// import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';
// import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://zutok-india.web.app">
//         Zutok Softwares Private limited
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const tiers = [
  {
    title: 'Robotics',
    price: '',
    description: [
      'Expertised robotics engineering team',
      'Innovative ideas and creativity',
      'Efficient full scale development and electronics',
      'Expertise team handling the project'
    ],
    time:'',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  // {
  //   title: 'Web, Mobile & Desktop apps',
  //   subheader: 'Most popular',
  //   price: '89,900',
  //   description: [
  //     'Perfect for Startups and Ecommerce',
  //     'Free Business analysis software as a compliment',
  //     'Unlimited users',
  //     'Unlimited storage',
  //     'Rs. 19,900/year for maintenance (from 2nd year onwards)',
  //     'Help center access',
  //     'High Priority email support',
  //   ],
  //   time:'',
  //   buttonText: 'Contact us',
  //   buttonVariant: 'outlined',
  // },
  
  
  {
    title: 'Enterprise Softwares',
    price: '',
    description: [
      'Perfect for large organiztions',
      // 'Complex applications and integrations',
      'Equipped with Business analysis',
      'On-Premise support',
      'Expertise team handling the project'
    ],
    time:'',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  
  // {
  //   title: 'IOT',
  //   price: '',
  //   description: [
  //     'Next big thing for Industry 4.0',
  //     'Innovative ideas and creativity',
  //     'Efficient full scale development and electronics',
  //     'Expertise team handling the project'
  //   ],
  //   time:'',
  //   buttonText: 'Contact us',
  //   buttonVariant: 'outlined',
  // },

  {
    title: 'APIs & Integrations',
    price: '',
    description: [
      'Suitable for multiple organiztions and collaborations',
      'Complex applications and integrations',
      'Various tech support including REST, GraphQL, SOAP, etc.,',
      'On-Premise support',
      'Expertise team handling the project'
    ],
    time:'',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  // {
  //   title: 'Business Websites',
  //   price: '28,000',
  //   description: [
  //     'Perfect for Business brochures, portfolios, documentaions',
  //     'Most suitable for Blog sites',
  //     'Full development and maintenance support',
  //     'Email support for changes',
  //   ],
  //   time:'/ year',
  //   buttonText: 'Contact us',
  //   buttonVariant: 'outlined',
  // },
  
];

// const footers = [
//   {
//     title: 'Company',
//     description: ['Team', 'History', 'Contact us', 'Locations'],
//   },
//   {
//     title: 'Features',
//     description: [
//       'Cool stuff',
//       'Random feature',
//       'Team feature',
//       'Developer stuff',
//       'Another one',
//     ],
//   },
//   {
//     title: 'Resources',
//     description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
//   },
//   {
//     title: 'Legal',
//     description: ['Privacy policy', 'Terms of use'],
//   },
// ];

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Pricing() {
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'outside' } }} /> */}
      <CssBaseline />
      
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="#e4f4f4"
          gutterBottom
        >
          Services
        </Typography>
        <Typography variant="h5" align="center" color="#e1e1e1" component="p">
          Pricing is subject to change based on your Region.
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container  component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={tier.title === 'Enterprise Softwares' ? 12 : 6}
              md={4}
            >
              <Card 
                  sx={{
                  marginTop: 5,
                  marginLeft: 3,
                  padding: 3,
                  borderRadius:4,
                  bgcolor:'#111111'
                  }}
              >
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                    color:'#949494'
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'dark'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[900],
                        color:'#ffffff'
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                      height:'200%'
                    }}
                  >
                    <Typography component="h2" variant="h3" color="#ffffff">
                    {/* ₹{tier.price} */}
                    </Typography>
                    <Typography variant="h6" color="#919191">
                      {/* {tier.time} */}
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="left"
                        key={line}
                        color='#ffffff'
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} href='mailto:vishwanath@zutok.in'>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {/* Footer */}
      
      {/* End footer */}
    </ThemeProvider>
  );
}
